import { defineNuxtPlugin } from '#imports';

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.provide('telemetryLog', async (data) => {
    try {
      if (!data || !data.event_name) return;

      const { $api } = useNuxtApp();
      const userStore = useUserStore();
      const dataForLongStore = useDataForLongStore();

      await $api.web().sendTelemetryEvent({
        event_name: data.event_name,
        session_id: useCookie('tm_session_id').value,
        order_id: dataForLongStore.order_id,
        contract_number: userStore?.user?.contract_number,
        payload: data?.payload,
        partner: useCookie('pageqs').value?.from2,
        wm_id: useCookie('pageqs').value?.wm_id,
      });
    } catch {}
  });
});
