<template>
  <NuxtLayout>
    <div
      id="pixel2"
      data-pid="18917"
      data-uid="c2cc7947-8e04-4f8b-b3ba-4321d9a713af"
    ></div>
    <NuxtPage />
  </NuxtLayout>
</template>

<script setup>
import { onMounted } from 'vue';
import { useAppStore } from '~/stores/app';
import { useUserStore } from '~/stores/user';

const appStore = useAppStore();
const userStore = useUserStore();
const route = useRoute();
const nuxtApp = useNuxtApp();

// const {
//   public: { nodeEnv },
// } = useRuntimeConfig();

nuxtApp.hook('page:finish', () => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
});

// const scriptTagInner = `(function(m, e, t, r, i, k, a) {
//     m[i] = m[i]

//     || function() {
//         (m[i].a = m[i].a || []).push(arguments)
//     };
//     m[i].l = 1 * new Date();
//     for (var j = 0; j < document.scripts.length; j++) {
//         if (document.scripts[j].src === r) {
//             return;
//         }
//     }
//     k = e.createElement(t), a = e.getElementsByTagName(t)[0], k.async = 1, k.src = r, a.parentNode.insertBefore(k, a)
// })
// (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

// ym(30533147, "init", {
//     clickmap: true,
//     trackLinks: true,
//     accurateTrackBounce: true,
//     webvisor: true
// });`;
// const nosctipTagInner = `<div><img src="https://mc.yandex.ru/watch/30533147" style="position:absolute; left:-9999px;" alt="" /></div>`;

useHead({
  title: '«Простой вопрос» — Личный кабинет',
  meta: [
    {
      hid: 'description',
      name: 'description',
      content:
        'Личный кабинет компании Простой вопрос. Для входа понадобится телефон и пароль. Для входа нажмите «Войти»',
    },
    {
      name: 'viewport',
      content: 'width=device-width, initial-scale=1, maximum-scale=1',
    },
  ],
  link: [
    { rel: 'icon', type: 'image/x-icon', href: '/favicon.ico' },
    { rel: 'apple-touch-icon', sizes: '180x180', href: '/apple-touch-icon.png' },
    { rel: 'apple-touch-icon', type: 'image/png', sizes: '32x32', href: '/favicon-32x32.png' },
    { rel: 'apple-touch-icon', type: 'image/png', sizes: '16x16', href: '/favicon-16x16.png' },
  ],

  // script: nodeEnv === 'production' ? [{ type: 'text/javascript', children: scriptTagInner }] : [],
  // noscript: nodeEnv === 'production' ? [{ children: nosctipTagInner }] : [],
});

userStore.isInitialGetUserStatesLoading = true;

onMounted(async () => {
  appStore.getSettings();

  if (
    ![
      'login',
      'r-slug',
      'getPassword',
      'emailVerification',
      'newPhoneVerification',
      'long',
    ].includes(route.name)
  ) {
    await userStore.getUserStates();
    userStore.isInitialGetUserStatesLoading = false;
  }
  if (route.query.token) {
    appStore.qsToken = route.query.token;
  }
});
</script>
