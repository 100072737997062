import { default as appealVSheL8HZRZMeta } from "/app/pages/appeal.vue?macro=true";
import { default as addavtJOfM7oQMeta } from "/app/pages/cards/add.vue?macro=true";
import { default as indexhEnu7n5u7IMeta } from "/app/pages/cards/index.vue?macro=true";
import { default as chatqOxyUSQXKlMeta } from "/app/pages/chat.vue?macro=true";
import { default as _91correction_type_939ry6ZHczkPMeta } from "/app/pages/correction/documents/[correction_type].vue?macro=true";
import { default as earlyRepaymentJ6fvMmZEk2Meta } from "/app/pages/correction/earlyRepayment.vue?macro=true";
import { default as prolongationbYDZfD6KDaMeta } from "/app/pages/correction/prolongation.vue?macro=true";
import { default as emailVerificationc5DfqUllptMeta } from "/app/pages/emailVerification.vue?macro=true";
import { default as faqIMkmXQpKKaMeta } from "/app/pages/faq.vue?macro=true";
import { default as feedbackOc9gyz0hXBMeta } from "/app/pages/feedback.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as invitebE9by1aL3FMeta } from "/app/pages/invite.vue?macro=true";
import { default as loginhHM0vSTW5jMeta } from "/app/pages/login.vue?macro=true";
import { default as longscnCaDd4kjMeta } from "/app/pages/long.vue?macro=true";
import { default as newPhoneVerificationDacYOg0MeyMeta } from "/app/pages/newPhoneVerification.vue?macro=true";
import { default as notifications883AI3QFtaMeta } from "/app/pages/notifications.vue?macro=true";
import { default as paymentHistoryvHZAwv7US7Meta } from "/app/pages/paymentHistory.vue?macro=true";
import { default as profileupBlgdy3vLMeta } from "/app/pages/profile.vue?macro=true";
import { default as _91slug_93trRDlnKnCiMeta } from "/app/pages/r/[slug].vue?macro=true";
export default [
  {
    name: "appeal",
    path: "/appeal",
    component: () => import("/app/pages/appeal.vue").then(m => m.default || m)
  },
  {
    name: "cards-add",
    path: "/cards/add",
    meta: addavtJOfM7oQMeta || {},
    component: () => import("/app/pages/cards/add.vue").then(m => m.default || m)
  },
  {
    name: "cards",
    path: "/cards",
    meta: indexhEnu7n5u7IMeta || {},
    component: () => import("/app/pages/cards/index.vue").then(m => m.default || m)
  },
  {
    name: "chat",
    path: "/chat",
    meta: chatqOxyUSQXKlMeta || {},
    component: () => import("/app/pages/chat.vue").then(m => m.default || m)
  },
  {
    name: "correction-documents-correction_type",
    path: "/correction/documents/:correction_type()",
    component: () => import("/app/pages/correction/documents/[correction_type].vue").then(m => m.default || m)
  },
  {
    name: "correction-earlyRepayment",
    path: "/correction/earlyRepayment",
    component: () => import("/app/pages/correction/earlyRepayment.vue").then(m => m.default || m)
  },
  {
    name: "correction-prolongation",
    path: "/correction/prolongation",
    component: () => import("/app/pages/correction/prolongation.vue").then(m => m.default || m)
  },
  {
    name: "emailVerification",
    path: "/emailVerification",
    meta: emailVerificationc5DfqUllptMeta || {},
    component: () => import("/app/pages/emailVerification.vue").then(m => m.default || m)
  },
  {
    name: "faq",
    path: "/faq",
    meta: faqIMkmXQpKKaMeta || {},
    component: () => import("/app/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: "feedback",
    path: "/feedback",
    component: () => import("/app/pages/feedback.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexN6pT4Un8hYMeta || {},
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "invite",
    path: "/invite",
    meta: invitebE9by1aL3FMeta || {},
    component: () => import("/app/pages/invite.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: loginhHM0vSTW5jMeta || {},
    component: () => import("/app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "long",
    path: "/long",
    meta: longscnCaDd4kjMeta || {},
    component: () => import("/app/pages/long.vue").then(m => m.default || m)
  },
  {
    name: "newPhoneVerification",
    path: "/newPhoneVerification",
    meta: newPhoneVerificationDacYOg0MeyMeta || {},
    component: () => import("/app/pages/newPhoneVerification.vue").then(m => m.default || m)
  },
  {
    name: "notifications",
    path: "/notifications",
    meta: notifications883AI3QFtaMeta || {},
    component: () => import("/app/pages/notifications.vue").then(m => m.default || m)
  },
  {
    name: "paymentHistory",
    path: "/paymentHistory",
    component: () => import("/app/pages/paymentHistory.vue").then(m => m.default || m)
  },
  {
    name: "profile",
    path: "/profile",
    meta: profileupBlgdy3vLMeta || {},
    component: () => import("/app/pages/profile.vue").then(m => m.default || m)
  },
  {
    name: "r-slug",
    path: "/r/:slug()",
    meta: _91slug_93trRDlnKnCiMeta || {},
    component: () => import("/app/pages/r/[slug].vue").then(m => m.default || m)
  }
]