import * as Sentry from '@sentry/vue';
import { UAParser } from 'ua-parser-js';
import { defineNuxtPlugin } from '#app';
import * as pkg from '~/package.json';

export default defineNuxtPlugin((nuxtApp) => {
  const {
    public: { sentry },
  } = useRuntimeConfig();
  const router = useRouter();

  if (sentry.environment !== 'production') {
    console.log('Release:', `${pkg.name}@${pkg.version}`, sentry.environment);
    console.log('DSN:', sentry.dsn || 'No dsn link provided');
    console.log('Environment:', sentry.environment || 'No sentry env provided');
  }

  const parser = new UAParser();
  parser.setUA(navigator.userAgent);
  const result = parser.getResult();
  const browser = result.browser;

  const isOld =
    (browser.name === 'Safari' && browser.major <= 14) ||
    (browser.name === 'Mobile Safari' && browser.major <= 14);

  if (!isOld) {
    Sentry.init({
      app: nuxtApp.vueApp,
      dsn: sentry.dsn,
      release: `${pkg.name}@${pkg.version}`,
      environment: sentry.environment,

      trackComponents: true,
      hooks: ['activate', 'create', 'destroy', 'mount', 'update'],
      integrations: [
        new Sentry.BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
          tracePropagationTargets: ['localhost', /^\//],
        }),
        Sentry.browserTracingIntegration({ router }),
        Sentry.replayIntegration({
          blockAllMedia: false,
          useCompression: false,
          maskAllInputs: false,
          maskAllText: false,
          networkDetailAllowUrls: [
            'localhost:3080',
            /^http:\/\/localhost:3080\//,
            /^https:\/\/api-lk\.prostoyvopros\.ru\//,
            /^https:\/\/api-lkstage\.prostoyvopros\.ru\//,
            /^https:\/\/api-chat\.bytepass\.ru\//,
            /^https:\/\/pay\.prostoyvopros\.ru\//,
            /^https:\/\/paystage\.prostoyvopros\.ru\//,
          ],
        }),
        Sentry.metrics.metricsAggregatorIntegration(),
      ],

      tracePropagationTargets: [
        'localhost:3080',
        /^https:\/\/api-lk\.prostoyvopros\.ru\//,
        /^https:\/\/api-lkstage\.prostoyvopros\.ru\//,
        /^https:\/\/api-chat\.bytepass\.ru\//,
      ],
      tracesSampleRate: 1, // Change in prod

      replaysSessionSampleRate: 0.1, // Change in prod
      replaysOnErrorSampleRate: 1.0, // Change in prod if necessary

      debug: false,

      beforeSend(event, hint) {
        // Check if it is an exception, and if so, log it.
        if (event.exception) {
          // eslint-disable-next-line
          console.error(`[sentry]: (${hint.originalException})`, { event, hint });
        }
        // Continue sending to Sentry
        return event;
      },
    });
    try {
      const user = useCookie('user')?.value?.user;
      if (user && Object.keys(user).length > 0) {
        const username = [user.phone, user.last_name, user.first_name, user.secondary_name]
          .filter((i) => i !== undefined && i !== '')
          .join(' ');

        const pageqs = useCookie('pageqs')?.value;
        const id = pageqs
          ? pageqs.click_id ||
            pageqs.clickId ||
            pageqs.click ||
            pageqs.utm_term ||
            pageqs.transaction_id ||
            ''
          : '';
        Sentry.setUser({ username, id });
      }
    } catch (err) {
      // eslint-disable-next-line
      console.log(err);
    }
  }
});
