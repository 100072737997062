import { defineStore } from 'pinia';

export const useProcessingStore = defineStore(
  'proccessing',
  () => {
    const progress = ref(1);
    const filesProgress = ref(1);
    const serverTimestamp = ref('');

    const $reset = () => {
      progress.value = 1;
    };

    return {
      progress,
      filesProgress,
      serverTimestamp,
      $reset,
    };
  },
  {
    persist: true,
  },
);
