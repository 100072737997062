import { defineNuxtPlugin } from '#app';
import { useAuthStore } from '~/stores/auth';

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.provide('login', async (data) => {
    const { $api } = useNuxtApp();

    const router = useRouter();

    const appStore = useAppStore();
    const authStore = useAuthStore();
    const userStore = useUserStore();

    let loginData = {};
    if (data.token) {
      loginData = {
        token: data.token,
      };
    } else {
      loginData = {
        phone: data.phone,
        password: data.password,
      };
    }

    await authStore.login(loginData);

    if (data.token && data.names) {
      await $api.web().updateUserInfo(data.names);
      userStore.user.last_name = data.names.last_name;
      userStore.user.first_name = data.names.first_name;
      if (data.names.secondary_name) {
        userStore.user.secondary_name = data.names.secondary_name;
      }
    }

    await userStore.getUserStates();

    appStore.setInitialValues();
    userStore.isInitialGetUserStatesLoading = false;

    // const username = [
    //   userStore.user.phone,
    //   userStore.user.last_name,
    //   userStore.user.first_name,
    //   userStore.user.secondary_name,
    // ]
    //   .filter((i) => i !== undefined && i !== '')
    //   .join(' ');

    // themeChange(appStore);
    router.push('/long');
  });
});
