<template>
  <NuxtLayout name="empty">
    <section
      v-if="!isLoading"
      class="flex flex-col gap-[32px] justify-between w-full lg:h-full mx-auto my-auto items-center"
    >
      <h1>Страница не найдена</h1>
      <div class="flex flex-col gap-[32px] lg:gap-[40px] items-center justify-center w-full">
        <img
          class="w-3/5 max-w-[440px]"
          src="~/assets/images/error.svg"
          alt="Image not found"
        />

        <div
          class="flex flex-col lg:w-1/2 w-4/5 gap-[18px] lg:gap-[32px] pb-[16px] justify-between lg:items-stretch items-center text-center lg:text-left"
        >
          <p class="text-lg lg:text-xl 2xl:text-2xl text-dnd_dark_grey text-center">
            Похоже что-то пошло не так
          </p>
          <div class="flex min-w-[150px] w-fit self-center">
            <UiButton
              v-if="authStore.isLoggedIn"
              @click="navigateTo('/')"
            >
              Перейти в личный кабинет
            </UiButton>
            <UiButton
              v-else
              @click="navigateTo('/login')"
            >
              Авторизация
            </UiButton>
          </div>
        </div>
      </div>
    </section>
    <UiIsLoading v-else />
  </NuxtLayout>
</template>

<script setup>
defineProps({
  error: {
    type: Object,
    default: null,
  },
});

const authStore = useAuthStore();
const isLoading = ref(true);

onMounted(() => (isLoading.value = false));
</script>

<style></style>
